import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Formulario from '../../../componentes/herramientas/formulario';
import Dialogo from '../../../componentes/herramientas/dialogo';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { genera_formulario, conexiones, Form_todos, Ver_Valores } from '../../../constantes';
import moment from 'moment';
import Confirmar from './confirmar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function Verificar(props) {
    const [formulario, setFormulario] = useState();
    const [pendiente, setPendiente] = useState();
    const [rechazado, setRechazado] = useState();
    const [capture, setCapture] = useState();
    const [dialogo, setDialogo] = useState({open:false});
    const [actualizar, setActualizar] = useState(false);
    const Inicio = async(cargar=false) =>{
        let {datos} = props
        
        if (cargar && props.Cambio) props.Cambio({
            pantalla:'Pasos', 
            datos, 
            paso:'representante', 
        })
        // let nuevo = Form_todos('Form_Representados');
        let nuevos = await genera_formulario({valores:datos.valores, campos: Form_todos('Form_Representados', Config) })
        nuevos.titulos.representados.nopaginar=true;
        nuevos.titulos.representados.noeliminar=true;
        nuevos.titulos.representados.Acciones=['hola']
        // console.log(nuevos, datos.valores)
        nuevos.titulos.representados.style={height:window.innerWidth > 750 ? window.innerHeight * 0.35 : window.innerHeight * 0.3};//220};
        setFormulario(nuevos)
    }
    const Cancelar = async(id)=>{
        const resp= await conexiones.Eliminar({_id:id},['uecla_Pago']);
        if (resp.Respuesta==='Ok'){
            setDialogo({open:false});
            Inicio(true);
        }
    }
    const Mostrar = (dato)=>{
        let {datos} = props
        setDialogo({
            open:true,
            tam:'lg',
            Titulo:'Pago Pendiente',
            Cuerpo: <Confirmar {...{pantalla:'Pasos', datos, 
                                    Meses:{}, 
                                    Mensualidades:dato.valores.Mensualidades, 
                                    Formas:undefined, 
                                    Formas_pago:dato.valores.Formas_pago, 
                                    formas_pago:dato.valores.Formas_pago,
                                    formapago:dato.valores.Formas_pago,
                                    Subtotalvalor:dato.valores.Subtotalvalor,
                                    Totales: dato.valores.Totales,
                                    files:dato.valores.files,
                                    valorCambio:dato.valores.valorCambio,
                                    Pendiente:dato.valores.estatus==='0' ? true : dato.valores.Pendiente,
                                    Motivo_rechazo: dato.valores.Motivo_rechazo,
                                    id_pago:dato.valores.id_pago ? dato.valores.id_pago : dato._id,
                                    Cancelar
                                }}
                    />,
            Cerrar: ()=>{
                setDialogo({open:false});
            },
        })
    }
    const EliminarCap = (dato)=>{
        setDialogo({open:false});
        confirmAlert({
            title: `Eliminar`,
            message:`Desea eliminar referencia: ${dato.valores.referencia}`,
            buttons: [
                {label: 'SI', onClick: async()=>{
                    const resp= await conexiones.Eliminar({_id:dato._id},['uecla_Whatsapp_Capture']);
                    if (resp.Respuesta==='Ok'){
                        Inicio(true);
                    }
                }},
                {label: 'NO'}
            ]
        });
    }
    
    const Mostrar1 = (dato)=>{
        const image = dato.valores.media.data;
        setDialogo({
            open:true,
            Titulo: <Box>Captures enviados por WhatSapp
                        <IconButton color={'primary'} title={'Eliminar Capture'} onClick={()=>EliminarCap(dato)} >
                            <Icon style={{color:'red'}}>delete_outline</Icon>
                        </IconButton>
                    </Box>,
            Cuerpo: <Box component={'div'} sx={{textAlign:'center'}}>
                        <img src={`data:image;base64,${image}`} alt={'Capture'} />
                    </Box>,
            Cerrar: ()=>{
                setDialogo({open:false});
            },
        })
    }
    const {Config}=props;
    useEffect(()=>{
        // console.log('Crear ActualizarPago')
        setActualizar(true);
        Ver_Valores().socket.on('ActualizarPago',data=>{
            //corregir

            // console.log('Por aqui', actualizar, data)
            if (actualizar)
                Inicio(true);
        })
        Ver_Valores().socket.on('Actualizar',data=>{
            if (data==='uecla_Pago' && actualizar){
                Inicio(true);
            }
        })
        Inicio();
        return ()=>{
            // console.log('Destruir ...')
            setActualizar(false);
            Ver_Valores().socket.off('Actualizar');
            Ver_Valores().socket.off('ActualizarPago');
        }
    },[])
    useEffect(()=>{
        if (props.Verificar){
            setCapture(props.Verificar.pendienteWhatsapp);
            setPendiente(props.Verificar.pend);
            setRechazado(props.Verificar.rech);
        }
        return ()=>{
            
        }
    },[props.Verificar])
    return (
        <Box sx={{ textAlign:'left', pb:9 }}>
            {pendiente && pendiente.length!==0
                ?   <Alert severity="warning">Pagos pendientes: 
                    {
                        pendiente.map((val, i)=>
                        <Link href="#" key={val._id}
                            onClick={()=> Mostrar(val)}
                        >
                            {` ${moment(val.valores.fecha).format('DD/MM/YYYY')} ${i < pendiente.length - 1 ? ',' : ''} `}
                        </Link>
                    )}</Alert>
                : null
            }
            {rechazado && rechazado.length!==0
                ?   <Alert severity="error">Pagos Rechazados: 
                    {
                        rechazado.map((val, i)=>
                        <Link href="#" key={val._id}
                            onClick={()=> Mostrar(val)}
                        >
                            {` ${moment(val.valores.fecha).format('DD/MM/YYYY')} ${i < rechazado.length - 1 ? ',' : ''} `}
                        </Link>
                    )}</Alert>
                : null
            }
            {capture && capture.length!==0
                ?   <Alert severity="warning">Captures enviados por WhatSapp:
                    {
                        capture.map((val, i)=>
                        <Link href="#" key={val._id}
                            onClick={()=> Mostrar1(val)}
                        >
                            {` ${moment(val.createdAt).format('DD/MM/YYYY')} ${i < capture.length - 1 ? ',' : ''} `}
                        </Link>
                    )}</Alert>
                : null
            }
            <Typography variant={window.innerWidth > 750 ? "h6" : "subtitle1"} component="div" sx={{...Config ? {color:Config.Estilos.Input_label.color} : {} }}>
                DATOS DEL REPRESENTANTE
            </Typography>
            <Divider />
            
            <Typography variant={window.innerWidth > 750 ? "h6" : "subtitle1"}  component="div" sx={{...Config ? {color:Config.Estilos.Input_label.color} : {} }}>
                Cedula: {props.datos.valores.cedula}
            </Typography>
            <Typography variant={window.innerWidth > 750 ? "h6" : "subtitle1"} component="div" sx={{...Config ? {color:Config.Estilos.Input_label.color} : {} }}>
                Nombres y Apellidos : {props.datos.valores.nombres} {props.datos.valores.apellidos}
            </Typography>
            <Typography variant={window.innerWidth > 750 ? "h6" : "subtitle1"} component="div" sx={{...Config ? {color:Config.Estilos.Input_label.color} : {} }}>
                Direccion : {props.datos.valores.direccion}
            </Typography>
            
            {formulario
                ? <Formulario {...formulario} Config={Config}/>
                : null
            }
            <Dialogo  {...dialogo} config={props.Config}/>
        </Box>
    );
}
