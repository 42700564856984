import React, {useState, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
import Tabla from '../../../componentes/herramientas/tabla';
import { conexiones, Ver_Valores} from '../../../constantes';
import { Form_todos, Titulos_todos } from '../../../constantes/formularios';
import Cargando from '../../../componentes/esperar/cargar';

function NotasA (props) {
    const {Datos} = props;
    const [state, setState]= useState({esperar:true, est_cantidad:0, est_datos:[]});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = (campos, datos) =>{
        let {valores}= datos
        valores={...valores,
            periodo:Datos.periodo,
            docente:{
                _id:Datos.docente._id,
                cedula:Datos.docente.cedula,
                nombres:Datos.docente.nombres,
                apellidos:Datos.docente.apellidos,
            },
            asignatura:Datos.asignatura,
            grado:Datos.grado,
            seccion: Datos.seccion
        }
        return valores
    }

    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_NotasA`, Config)
        cambiarState({esperar:false, titulos})
    }
    const Ordenar = (datos)=>{
        // console.log(datos, Datos.grado, Datos.seccion)
        // let resultados= datos.filter(f=> {
        //    return  f.valores.periodo===Datos.periodo 
        //     && String(f.valores.docente._id)===String(Datos.docente._id)
        //     && f.valores.grado===Datos.grado 
        //     && f.valores.seccion===Datos.seccion
        //     && f.valores.asignatura._id===Datos.asignatura._id  
        // }
        // )
        return datos
    }
    const GuardarNOtas = async()=>{
        const {est_datos}= state;
        for (var i=0; i<est_datos.length ; i++){
            const dato = est_datos[i];
            await conexiones.Guardar({_id:dato._id, valores:dato.valores, multiples_valores:true},'uecla_NNota');
        }
        console.log('Listo')
    }
    const VerNOtas = async()=>{
        let result= await conexiones.Leer_C(['uecla_Nota','uecla_Evaluacion'],{
            uecla_Nota:{},
            uecla_Evaluacion:{}
        })
        if (result.Respuesta==='Ok'){
            let titulos = await Titulos_todos(`Titulos_NotasA`, Config)
            let notas = [];
            let nuevo=[];
            let datos = result.datos.uecla_Nota.sort((a,b)=> a.valores.grado>b.valores.grado   ? 1 : -1)//.sort((a,b)=>a.valores.cedula>b.valores.cedula ? -1 : 1)
            const evaluaciones = result.datos.uecla_Evaluacion;
            let anterior={}
            //datos.map(val=>{
            for (var i=0;i<datos.length;i++){
                const val = datos[i];
                const pos = nuevo.findIndex(f=> f.valores._id_estudiante===val.valores._id_estudiante && f.valores.periodo===val.valores.periodo);
                anterior={}
                let docente = "";
                let _id_docente = '';
                Object.keys(val.valores).map(campo=>{
                    if(campo.indexOf('nota-')===0){
                        const posn = evaluaciones.findIndex(f=> String(f._id)===String(campo.split('-')[1]));
                        anterior[campo]= val.valores[campo];
                        if (posn!==-1){
                            
                            anterior[`${campo}-nombre`]= evaluaciones[posn].valores.nombre;
                            anterior[`${campo}-lapso`]= evaluaciones[posn].valores.lapso.titulo;
                            anterior[`${campo}-asignatura`]= evaluaciones[posn].valores.asignatura._id;
                            //anterior[`${campo}-asignatura-nombre`]= evaluaciones[posn].valores.asignatura.asignatura;
                            _id_docente= evaluaciones[posn].valores.docente._id;
                            docente = evaluaciones[posn].valores.docente.apellidos +" "+evaluaciones[posn].valores.docente.nombres;
                            // anterior[`${campo}-docente-_id_docente`]= evaluaciones[posn].valores.docente._id;
                            // anterior[`${campo}-docente-nombres`]= evaluaciones[posn].valores.docente.apellidos +" "+evaluaciones[posn].valores.docente.nombres;
                        }

                    }else if ((campo.indexOf('nota')!==-1 || campo.indexOf('lapso')!==-1)&& val.valores[campo]!==''){
                        anterior[campo]= val.valores[campo]
                    }
                    return campo
                })
                // console.log(anterior)
                const pos11= titulos.findIndex(f=>f.field===`1lapso-${val.valores.asignatura._id}`)
                if (pos11===-1){
                    titulos=[...titulos,{
                        default: "",
                        field: `1lapso-${val.valores.asignatura._id}`,
                        formato: (dato)=> {
                            
                            return dato.valores[`1lapso-${val.valores.asignatura._id}`]
                        },
                        tipo: "",
                        title: val.valores.asignatura.titulo,
                        type: ""
                    }]
                }
                if (pos===-1){
                    nuevo=[...nuevo,{
                        ...val,
                        valores:{
                            //-----------------Datos fijos-----------------
                            _id_estudiante:val.valores._id_estudiante,
                            periodo:val.valores.periodo,
                            cedula:val.valores.cedula,
                            apellidos:val.valores.apellidos,
                            nombres:val.valores.nombres,
                            fecha_nacimiento:val.valores.fecha_nacimiento,
                            lugar_nacimiento:val.valores.lugar_nacimiento,
                            grado:val.valores.grado,
                            seccion:val.valores.seccion,

                            //-----------------Datos asignatura-----------------
                            [`asignatura_${val.valores.asignatura._id}`]:val.valores.asignatura._id,
                            [`asignatura_${val.valores.asignatura._id}_nombre`]:val.valores.asignatura.asignatura,
                            // [`asignatura_${val.valores.asignatura._id}_titulo`]:val.valores.asignatura.titulo,
                            [`asignatura_${val.valores.asignatura._id}_id_docente`]:val.valores._id_docente ? val.valores._id_docente : _id_docente,
                            [`asignatura_${val.valores.asignatura._id}_docente`]:val.valores.docente ? val.valores.docente : docente,
                            ...anterior
                        }
                    }]

                }else{
                    nuevo[pos]={
                        ...nuevo[pos],
                        valores:{
                            ...nuevo[pos].valores,
                            //-----------------Datos asignatura-----------------
                            [`asignatura_${val.valores.asignatura._id}`]:val.valores.asignatura._id,
                            [`asignatura_${val.valores.asignatura._id}_nombre`]:val.valores.asignatura.asignatura,
                            [`asignatura_${val.valores.asignatura._id}_titulo`]:val.valores.asignatura.titulo,
                            [`asignatura_${val.valores.asignatura._id}_id_docente`]:val.valores._id_docente ? val.valores._id_docente : _id_docente,
                            [`asignatura_${val.valores.asignatura._id}_docente`]:val.valores.docente ? val.valores.docente : docente,
                            ...anterior

                        }
                    }
                }

                // if (val.valores._id_estudiante!==anterior._id_estudiante || val.valores.periodo!==anterior.periodo){
                //     anterior={
                //         _id_estudiante:val.valores._id_estudiante,
                //         periodo:val.valores.periodo
                //     }
                // }else{
                //     notas=[...notas, val]
                // }
            //})
            }
            // nuevo=nuevo.sort((a,b)=> a.valores.grado>b.valores.grado   ? 1 : -1)
            console.log(datos.length, nuevo, titulos)
            cambiarState({est_datos:nuevo, est_cantidad:nuevo.length, titulos})
        }
    }
    const Duplicados = async()=>{
        let result= await conexiones.Leer_C(['uecla_Nota'],{
            uecla_Nota:{}
        })
        if (result.Respuesta==='Ok'){
            let duplicados = []
            let datos = result.datos.uecla_Nota.sort((a,b)=>a.valores.cedula>b.valores.cedula ? -1 : 1)
            let anterior={}
            datos.map(val=>{
                if (val.valores._id_estudiante!==anterior._id_estudiante || val.valores.asignatura._id!==anterior._id_asignatura){
                    anterior={
                        _id_estudiante:val.valores._id_estudiante,
                        _id_asignatura:val.valores.asignatura._id
                    }
                }else{
                    duplicados=[...duplicados, val]
                }
            })
            console.log(datos.length, duplicados)
            cambiarState({est_datos:duplicados, est_cantidad:duplicados.length})
        }
    }
    const Eliminar = async()=>{
        return
        const {est_datos} = state;
        for (var i=0; i<est_datos.length; i++){
            const dato = est_datos[i];
            await conexiones.Eliminar(dato,['uecla_Nota']);
        }
        Duplicados()
    }
    useEffect(()=>{
        Inicio()
    }, [props])
    const {Config}= props;
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :(
        
            <Tabla
                Titulo={'Notas'}
                Config={Config ? Config : Ver_Valores().config}
                alto={window.innerHeight * 0.76}
                titulos = {state.titulos}
                table = {'uecla_Nota'}
                cantidad={state.est_cantidad ? state.est_cantidad : null}
                datos={state.est_datos}
                //AccionColumna={Abrir}
                //AccionD={Abrir1}
                Noactualizar
                cargaporparte={false}
                sinpaginacion={true} 
                acciones={
                    <Stack direction="row" spacing={0.5}>
                        <IconButton color={'primary'} title={'Ver notas'} onClick={VerNOtas}>
                            <Icon style={{color:'#fff'}}>pageview</Icon>
                        </IconButton>
                        {state.est_datos && state.est_datos.length!==0
                            ?   <IconButton color={'primary'} title={'Guardar nuevo formato notas'} onClick={GuardarNOtas}>
                                    <Icon style={{color:'#fff'}}>save</Icon>
                                </IconButton>
                            :   null
                        }
                        
                        <IconButton color={'primary'} title={'Ver Duplicados'} onClick={Duplicados}>
                            <Icon style={{color:'#fff'}}>dynamic_feed</Icon>
                        </IconButton>
                        <IconButton color={'primary'} disabled={true} title={'Ver Duplicados'} onClick={Eliminar}>
                            <Icon style={{color:'#fff'}}>delete_forever</Icon>
                        </IconButton>
                    </Stack>

                }

                // multiples_valores={true}
                // Agregar_mas={false}
                // Condiciones={Condiciones}
                // Columnas={2} 
                // Form_origen = {Form_todos(`Form_Evaluacion`, Config)}
                // Titulo_tabla={'Notas'}
                
                // Eliminar_props={(dato)=>{
                //     return `Desea eliminar`
                // }}
                // Titulo_dialogo ={(dato)=> dato._id ? `Evaluación`: `Nueva Evaluación`}
                
                // cargaporparte = {true}
                // ordenar={Ordenar}
                // Accion={(dato)=>console.log(dato)}
                // Acciones={
                //     <Stack direction="row" spacing={0.5}>
                //         <IconButton color={'primary'} title={'Ver Duplicados'} onClick={Duplicados}>
                //             <Icon style={{color:'#fff'}}>dynamic_feed</Icon>
                //         </IconButton>
                //     </Stack>

                // }
                // Acciones1={props.Titulo ? props.Titulo : ''}
                // sinpaginacion={false}
            />
        
      
    )
}

export default NotasA;