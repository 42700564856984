import React, {useState, useEffect} from 'react';
import TablaMultiple from '../../../../componentes/herramientas/tabla/tabla_multiple';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { conexiones, genera_formulario, Ver_Valores} from '../../../../constantes';
import { Form_todos, Titulos_todos } from '../../../../constantes/formularios';
import Cargando from '../../../../componentes/esperar/cargar';
import Formulario from '../../../../componentes/herramientas/formulario';

function Docenten (props) {
    const {Config}= props;
    const {User} = Ver_Valores();
    const [state, setState]= useState({esperar:true});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = (campos, datos) =>{
        let {valores}= datos
        if (valores.passwordn!==''){
            console.log('>>>>>>>>>>>',valores.passwordn)
            valores.newpassword=valores.passwordn;
            delete valores.passwordn
            delete valores.passwordc
        }
        return valores

    }

    const Guardar = async(valores, campos)=>{
        let nuevos;
        
        valores = Condiciones( campos, {valores});
        // if (!valores.finalizado_condicion){
            nuevos= await conexiones.Guardar({ valores, multiples_valores:true},'uecla_docente');
        // }else{
        //     nuevos=valores
        // }
        return nuevos    
    }
    const Cancelar = async()=>{
        Inicio();
    }
    const Inicio = async() =>{
        const permiso = [0,1].indexOf(Number(User.categoria))!==-1 
                        || (User.permisos && User.permisos.indexOf('Horario_modificar')!==-1);
        
        if (User.categoria===3 && !permiso){
            const buscar = User.personal ? User.personal._id : User._id
            const resulta= await conexiones.Leer_C(['uecla_docente'],{
                uecla_docente:{_id:buscar}
            })
            
            if (resulta.Respuesta==='Ok'){
                let formulario = await genera_formulario({valores:{_id:resulta.datos.uecla_docente[0]._id,...resulta.datos.uecla_docente[0].valores},campos:Form_todos('Form_Docente',Config)});
                formulario={
                    ...formulario,
                    botones:[
                        {
                          name:'guardar', label:'Guardar', title:'Guardar ',
                          variant:"contained", color:"success", icono:<CheckIcon/>,
                          onClick: Guardar, validar:'true', 
                          sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                        },
                        {
                          name:'cancelar', label:'Cancelar', title:'Cancelar',
                          variant:"contained",  icono:<CancelIcon/>,
                          sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                          onClick: Cancelar
                        },
                    ]
                }
                cambiarState({esperar:false, formulario, permiso})
            }
            // cambiarState({esperar:false, titulos})
        }else{
            const titulos = await Titulos_todos(`Titulos_Docente`, Config)
            cambiarState({esperar:false, titulos, permiso})
        }
    }
    useEffect(()=>{
        Inicio()
    }, [props])

    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :
    state.permiso 
        ?(
            <TablaMultiple
                Config={Config ? Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                Condiciones={Condiciones}
                Columnas={2} 
                Form_origen = {Form_todos(`Form_Docenten`, Config)}
                Titulo_tabla={'Docentes'}
                Table = {'uecla_Docente'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar`
                }}
                Titulo_dialogo ={(dato)=> dato._id ? `Registro `: `Nuevo Registro `}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
            />
      
        ):
        Number(User.categoria)===3
            ?   <Formulario {...state.formulario} />
            : null
}

export default Docenten;