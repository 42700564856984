import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialogo from '../../../../componentes/herramientas/dialogo';
import { conexiones, Ver_Valores, Titulos_todos, genera_formulario, Form_todos } from '../../../../constantes';
import Formulario from '../../../../componentes/herramientas/formulario';
import Tabla from '../../../../componentes/herramientas/tabla';
import Esperar from '../../../../componentes/esperar/cargar';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';

export default function HorasDocentes(props) {
    const [docentes, setDocentes] = useState();
    const [dialogo, setDialogo] = useState({open:false});
    const [actualizar, setActualizar] = useState(false);
    const Inicio = async(fecha = new Date()) =>{
        setActualizar(true);
        
        let docent ={...props.Docentes}
        docent.datos = docent.datos.filter(f=> !f.valordia)
        setDocentes({...docent})
        setActualizar(false);
    }
    
    const Guardar = async(valores)=>{
        const nuevos= await props.Guardar(valores)
        if (nuevos.Respuesta==='Ok'){
            setDialogo({...dialogo,open:false})
        }
        return nuevos
    }
    const Abrir = async(columna, valor)=>{
        const {titulos} = docentes;
        
        if (columna.field.indexOf('F-')!==-1 || ['cedula','nombres','apellidos','horas'].indexOf(columna.field)!==-1){
            return
        }
                 
        const valorN= {

            [columna.field]:valor[columna.field],
            ['condicion-'+columna.field]:valor['condicion-'+columna.field],
        }
        
        let formato = {
            columna:2,
            value:[
                {
                    label: `${valor.nombres} ${valor.apellidos}`,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    variant:'h6',
                    solo:true
                },
                
            ]
        }
        let Titulo = 'Notas';
        const dias = {
            Lu:'Lunes', Ma:'Martes', Mi:'Miercoles', Ju:'Jueves', Vi:'Viernes',
            Sa:'Sabado', Do:'Domingo'
        }
        Object.keys(valorN).map(val=>{
            const pos = titulos.findIndex(f=> `${f.field}`===val);
            if (pos!==-1 ){
                const dia = titulos[pos].field.split('-');
                
                Titulo = `${dias[dia[0]]} ${dia[1]}`;
                
                formato.value =[...formato.value,
                    {
                        label: `Horas a Cumplir: ${valor[`${dia[0]}  F-${dia[1]}`]}`,
                        name:'horascumplir',
                        nombre:'horascumplir',
                        tipo:'mensaje1',
                        title: ``,
                        variant:'h6',
                        solo:true
                    },
                    {
                        label: `Horas Cumplidas`,
                        name:val,
                        nombre:val,
                        tipo:'input',
                        title: `${titulos[pos].title}`,
                        type:'number'
                    },
                    {
                        name: `condicion-${val}`,
                        nombre: `condicion-${val}`,
                        "tipo": "lista_multiuso",
                        label: "Condición",
                        title: "Condición ",
                        "lista": "lista_condicion_nomina",
                        "getOptionLabel": [
                            "titulo"
                        ],
                    }
                ]
            }
            
            
            return val
        })
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:'xs',
            fullWidth: true,
            Titulo,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    useEffect(()=>{
        // console.log('Crear ActualizarPago')
        setActualizar(true);        
        Inicio();
        return ()=>{
            // console.log('Destruir ...')
            setActualizar(false);
        }
    },[props])
    
    const Config=Ver_Valores().config;
    const alto = Ver_Valores().tipo==='Web' 
        ?   window.innerHeight * 0.50
        :   window.innerHeight * 0.68
    return !docentes ? <Esperar open={true} Config={Config}/> :(
        <Box sx={{  }}>
            
            {docentes 
                ?   <Tabla  Titulo={`Docentes`}
                        alto={alto}
                        Config={Config ? Config : Ver_Valores().config}
                        titulos={docentes.titulos}
                        table={'uecla_docente'}
                        cantidad={docentes ? docentes.datos.length : null}
                        // cargacompleta={Actualizar_data}
                        datos={docentes.datos}
                        Noactualizar
                        cargaporparte={false}
                        sinpaginacion={true} 
                        AccionColumna={Abrir}
                        
                        acciones1={
                            <Stack direction="row" spacing={2}>
                                {Ver_Valores().config.Listas.lista_condicion_nomina.map(val=>
                                    <Stack direction="row" spacing={1} key={val._id}>
                                        <div style={{height:25, width:25, backgroundColor:val.color }}/>
                                        <Typography sx={{}}>{val.titulo}</Typography>
                                    </Stack>   
                                )}
                            </Stack>
                        }
                        
                    />
                :   null
            
            }
            <Dialogo  {...dialogo} config={Config}/>
            <Esperar open={actualizar} Config={Config}/>
        </Box>
    );
}
