import React, {useState, useEffect} from 'react';
// import Esperar from '../../../componentes/esperar/cargar';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
import { nuevo_Valores, Ver_Valores, conexiones} from '../../../constantes';
import { Form_todos, Titulos_todos } from '../../../constantes/formularios';
import Cargando from '../../../componentes/esperar/cargar';

function Representante_pagar (props) {
    const [state, setState]= useState({esperar:true});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }

    const Condiciones = (valores) =>{
        return valores
    }

    const Seleccion = (valores) =>{
        
        nuevo_Valores({datosActuales:{}});
        if (props.Cambio) props.Cambio({
            pantalla:'Pasos', 
            datos:valores, 
            Meses:{}, 
            Mensualidades:{meses:[]}, 
            Formas:undefined, Formas_pago:undefined, formas_pago:undefined, 
            Subtotalvalor:{
                abono:valores.valores.abono, 
                abonod:valores.valores.abonod,
                totald:0, total:0 
            },
            valorCambio:Ver_Valores().tasa.USD,
            Abono:true
        })
    }

    const Pendiente = async(dato)=>{
        nuevo_Valores({datosActuales:{}});
        let respu= await conexiones.Leer_C(['uecla_Representante'],{
            uecla_Representante:{_id:dato.valores.Representante}
        })
        
        if(respu.Respuesta==='Ok'){
            const repres = respu.datos.uecla_Representante[0];
            console.log(dato, repres.valores.abono)    
            if (props.Cambio) 
                props.Cambio({
                    pantalla:'Pasos',
                    paso:'representante', 
                    datos:repres, 
                    Meses:{}, 
                    Mensualidades:{meses:[]}, 
                    Formas:undefined, Formas_pago:undefined, formas_pago:undefined, 
                    Subtotalvalor:{
                        abono:repres.valores.abono ? repres.valores.abono : "0.00", 
                        abonod:repres.valores.abonod ? repres.valores.abonod : "0.00",
                        totald:0, total:0 
                    },
                    //formapago:dato.valores.Formas_pago,
                    //Subtotalvalor:dato.valores.Subtotalvalor,
                    //Totales: dato.valores.Totales,
                    // files:dato.valores.files,
                    valorCambio:Ver_Valores().tasa.USD,
                    //Pendiente:true,
                    id_pago: dato._id,
                    Abono:true,
                    Verificar:{pendienteWhatsapp:state.pendienteWhatsapp},
                    pendienteWhatsapp:state.pendienteWhatsapp
                })
                nuevo_Valores({datosActuales:{pendienteWhatsapp:state.pendienteWhatsapp}});
        }
        
    }
    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_Representante`, Config)
        let respu= await conexiones.Leer_C(['uecla_Whatsapp_Capture'],{
            uecla_Whatsapp_Capture:{"valores.estatus":"0"}
        })
        
        let pendienteWhatsapp=[];
        if(respu.Respuesta==='Ok'){
            pendienteWhatsapp = respu.datos.uecla_Whatsapp_Capture.filter(f=> f.eliminado===false || f.eliminado===undefined);
            //// eliminar repetidos
            let norepetido = [];
            pendienteWhatsapp.map(val=>{
                const pos = norepetido.findIndex(f=> f.valores.representante._id===val.valores.representante._id);
                if (pos===-1){
                    norepetido=[...norepetido, val];
                }
                return val
            })
            pendienteWhatsapp = norepetido;
        }
        
        cambiarState({esperar:false, titulos, pendienteWhatsapp})
    }

    useEffect(()=>{
        Ver_Valores().socket.on('Actualizar',data=>{
            if (data==='uecla_Whatsapp_Capture'){
                Inicio();
            }
        })
        Inicio()
        return ()=>{
            Ver_Valores().socket.off('Actualizar');
        }
    }, [props])

    const {Config}= props;
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :(
        <Box component={'div'}>
            <Stack sx={{ width: '100%' }} spacing={0.5}>
                {state.pendienteWhatsapp && state.pendienteWhatsapp.length!==0
                    ? <Alert severity="warning">Captures enviados por WhatSapp: 
                        {
                            state.pendienteWhatsapp.map((val, i)=>
                            <Link  key={val._id} sx={{cursor:'pointer'}} 
                                onClick={()=>Pendiente({...val, valores:{...val.valores, Representante:val.valores.representante._id}})}
                            >
                                {` ${val.valores.representante.nombres} ${val.valores.representante.apellidos}${i < state.pendienteWhatsapp.length - 1 ? ',' : ''} `}
                            </Link>
                        )}</Alert>
                    : null
                
                }
            </Stack>
            <TablaMultiple
                Config={props.Config ? props.Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                Condiciones={Condiciones}
                Columnas={2} 
                Form_origen = {Form_todos(`Form_Representante`, Config)}
                Titulo_tabla={'Representantes'}
                Table = {'uecla_Representante'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar`
                }}
                Titulo_dialogo ={(dato)=> dato._id ? `Registro `: `Nuevo Registro `}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
                Acciones={
                    <div>Abonos</div>
                }
                Seleccion={Seleccion}
                
            />
        
        </Box>
    )
}

export default Representante_pagar;