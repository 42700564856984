import React, {useState, useEffect} from 'react';
import Tabla from '../../../componentes/herramientas/tabla';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
import { Ver_Valores, conexiones, Excell} from '../../../constantes';
import { Form_todos, Titulos_todos } from '../../../constantes/formularios';
import Cargando from '../../../componentes/esperar/cargar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Icon from '@mui/material/Icon';
import banesco from '../../../imagenes/banesco.png';
import bv from '../../../imagenes/bv.png';
import moment from 'moment';
import Dialogo from '../../../componentes/herramientas/dialogo';

function Referencia (props) {
    
    const [state, setState]= useState({esperar:true, dialogo:{open:false}});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = (campos, datos) =>{
        let {valores}= datos
        return valores
    }

    const Refrescar = () =>{

    }

    const Seleccion = (dato)=>{
        console.log(dato);
    }
    const Actualizar_Referencia = async()=>{
        await conexiones.Actualizar_Referencia();
    }
    const Referncia_Banesco = async(archivo) =>{
        if (archivo.target.files[0]===undefined){
            return
        }
        cambiarState({cargando:true})
        const nuevo = await Excell(archivo)
        console.log(nuevo)
        const pagina = nuevo.datos.paginas[0].titulo;
        const datos = nuevo.datos.datos[pagina].rows;
        const campos = datos[0];
        let nuevodatos =[] 
        for (var j=1; j<datos.length;j++){
            const valor = datos[j];
            let dato={}
            valor.map((val,i)=>{
                // Ver como colocar fecha de excell a react
                const campo = campos[i]===undefined ? `Otro ${i}` : campos[i];
                dato[campo]= val;
                return val
            })
            if (dato.Referencia){
                let referencia = await conexiones.Leer_C(['uecla_Referencia'],{
                    uecla_Referencia:{$text: {$search: dato.Referencia, $caseSensitive: false}}
                })
                if (referencia.Respuesta==='Ok'){
                    // console.log(referencia.datos);
                    referencia = referencia.datos.uecla_Referencia
                    dato.referencias=referencia.map(rr=>rr.valores);
                }
            }
            nuevodatos=[...nuevodatos, dato];
        }
        const Titulos = await Titulos_todos('Titulos_Referencia_verificar')
        cambiarState({
            cargando:false,
            dialogo:{
                open:true,
                tam:'xl',
                Titulo:`Referencias en Archivo: ${nuevo.filename}`,
                Cerrar: ()=>{
                    cambiarState({dialogo:{open:false}});
                },
                Cuerpo : <Tabla  Titulo={`${nuevo.filename}`}
                                    alto={window.innerHeight * 0.6}
                                    Config={Ver_Valores().config}
                                    titulos={Titulos}
                                    table={''}
                                    cantidad={nuevodatos.length}
                                    // cargacompleta={Actualizar_data}
                                    datos={nuevodatos}
                                    Noactualizar
                                    cargaporparte={false}
                                    sinpaginacion={true}  
                                    acciones={
                                        <Stack direction="row" spacing={0.5}>
                                            
                                        </Stack>
                                    } 
                                />
            }
        });
    }
    const Referencia_Venezuela = async(archivo)=>{
        Referncia_Banesco(archivo)
    }
    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_Referencia`, Config)
        cambiarState({esperar:false, titulos})
    }
    const Botones = (datos)=>{
        console.log(datos)
        return
            <Stack direction="row" spacing={1}>
                <IconButton color={'primary'} title={'Refrescar valores de Referencia'} onClick={Refrescar}>
                    <AutorenewIcon style={color}/>
                </IconButton>
                <IconButton color={'primary'} title={'Actualizar referencia utilizadas en los recibos'} onClick={Actualizar_Referencia}>
                    <Icon style={color}>sticky_note_2</Icon>
                </IconButton>
            </Stack>
        
    }
    useEffect(()=>{
        Inicio()
    }, [props])
    const {Config}= props;
    const color =  props.Config.Estilos.Input_icono_t ? props.Config.Estilos.Input_icono_t : {};
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :(
        <div>
            <TablaMultiple
                Config={Config ? Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                Condiciones={Condiciones}
                Columnas={2} 
                Form_origen = {Form_todos(`Form_Docente`, Config)}
                Titulo_tabla={'Referencias'}
                Table = {'uecla_Referencia'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar`
                }}
                Titulo_dialogo ={(dato)=> dato._id ? `Registro `: `Nuevo Registro `}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
                Seleccion= {Seleccion}
                Acciones={
                    <Stack direction="row" spacing={1}>
                        {Ver_Valores().tipo!=='Electron'
                            ?   <div>
                                    <IconButton color={'primary'} title={'Actualizar referencia utilizadas en los recibos'} onClick={Actualizar_Referencia}>
                                        <Icon style={color}>sticky_note_2</Icon>
                                    </IconButton>
                                    
                                    <IconButton color="primary" aria-label="upload picture" component="label" title={'Verificar Referencia de Banesco'}>
                                        <input hidden required type={'file'} name={'file'} id={'file'} accept='.xlsx, .xls' onChange={Referncia_Banesco}/>
                                        {/* <Stack direction={'column'}> */}
                                        {/* <Icon style={color}>checklist</Icon> */}
                                        {/* <label style={{color, fontSize:10}}>Banesco</label> */}
                                        {/* </Stack> */}
                                        <img alt={'archivo'} src={banesco} height={35}/>
                                        
                                        {/* <label >{filename}</label> */}
                                    </IconButton>
                                    <IconButton color="primary" aria-label="upload picture" component="label" title={'Verificar Referencia de Banco de Venezuela'}>
                                        <input hidden required type={'file'} name={'file'} id={'file'} accept='.xlsx, .xls' onChange={Referencia_Venezuela}/>
                                        {/* <Stack direction={'column'}> */}
                                        {/* <Icon style={color}>checklist</Icon> */}
                                        {/* <label style={{color, fontSize:10}}>BV</label> */}
                                        {/* </Stack> */}
                                        <img alt={'archivo'} src={bv} height={35}/>
                                        
                                        {/* <label >{filename}</label> */}
                                    </IconButton>
                                </div>
                            :   null
                        }
                        
                    </Stack>
                }
                
            />
            <Cargando open={state.cargando} Config={Config}/>
            <Dialogo {...state.dialogo} config={Config}/>
        </div>
      
    )
}

export default Referencia;