import React, {useState, useEffect} from 'react';
import TablaMultiple from '../../../../componentes/herramientas/tabla/tabla_multiple';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { conexiones, genera_formulario, Ver_Valores} from '../../../../constantes';
import { Form_todos, Titulos_todos } from '../../../../constantes/formularios';
import Cargando from '../../../../componentes/esperar/cargar';
import Formulario from '../../../../componentes/herramientas/formulario';

function Personal (props) {
    const {Config}= props;
    const {User} = Ver_Valores();
    const [state, setState]= useState({esperar:true});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = (campos, datos) =>{
        let {valores}= datos
        if (valores.passwordn!==''){
            console.log('>>>>>>>>>>>',valores.passwordn)
            valores.newpassword=valores.passwordn;
            delete valores.passwordn
            delete valores.passwordc
        }
        return valores

    }

    const Guardar = async(valores, campos)=>{
        let nuevos;
        
        valores = Condiciones( campos, {valores});
        // if (!valores.finalizado_condicion){
            nuevos= await conexiones.Guardar({ valores, multiples_valores:true},'uecla_docente');
        // }else{
        //     nuevos=valores
        // }
        return nuevos    
    }
    const Cancelar = async()=>{
        Inicio();
    }
    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_Docente`, Config)
        cambiarState({esperar:false, titulos})
        
    }
    useEffect(()=>{
        Inicio()
    }, [props])

    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :
        (
            <TablaMultiple
                Config={Config ? Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                Condiciones={Condiciones}
                Columnas={2} 
                Form_origen = {Form_todos(`Form_Personal`, Config)}
                Titulo_tabla={'Personal'}
                Table = {'uecla_Personal'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar`
                }}
                Titulo_dialogo ={(dato)=> dato._id ? `Registro `: `Nuevo Registro `}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
            />
      
        )
}

export default Personal;