import Asignatura from "./asginatura";
import Horarios from "./horarios";
import Aulas from "./aulas";
import Notas from "./nnotas";
import NotasA from "./notasa";
import PNotas from "./procesosnotas";

export const colegio_academico_pantallas={
    Asignaturas:Asignatura,
    Horario:Horarios,
    Aulas,
    Notas,
    notasA:NotasA,
    procesosnotas:PNotas
}