import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
// import QRCode from "react-qr-code";
import { QRCode } from 'react-qrcode-logo'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { conexiones, Generar_id, Resultado_encontrados, Ver_Valores } from '../../../constantes';
import Scrollbars from '../../../componentes/herramientas/scrolbars';
import moment from 'moment';
import Logo from '../../../imagenes/logo.png'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function WhatsApp() {
    const [qr,setQr]= React.useState('');
    const [dispositivo,setDispositivo]= React.useState('');
    const [ tiempo, setTiempo] =React.useState();
    const [checked, setChecked] = React.useState([]);
    const [vbuscar, setvbuscar] = React.useState('');
    const [texto, setTexto] = React.useState('');
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };
    const Buscar = (value)=>{
        
        setvbuscar(value.target.value)
        if (value.target.value!==''){
            let nuevo = Resultado_encontrados(dispositivo.contactost, value.target.value)
            setDispositivo({...dispositivo, contactos:nuevo})
        }else{
            setDispositivo({...dispositivo, contactos:dispositivo.contactost})
        }
        
    }
    const SeleccionarT = () =>{
        if (dispositivo.contactos.length===checked.length){
            setChecked([]);
        }else{
            setChecked([...dispositivo.contactos])
        }
        
    }
    const Enviar = async()=>{
        console.log(checked, texto)
        const resulta = await conexiones.WhatsappEnviar(checked, texto);

    }
    React.useEffect(()=>{
        const Inicio = async()=>{
            const resp= await conexiones.WhatsappQR();
            if (resp.Respuesta==='Ok'){
                setTiempo(moment(resp.tiempo).format('HH:mm:ss a'))
                setQr(resp.QR ? resp.QR : '');
                
                setDispositivo({dispositivo:resp.dispositivo, contactos:resp.contactos, contactost:resp.contactos})
            }
            
            Ver_Valores().socket.on('whatsappqr-UECLA',data=>{
                setTiempo(moment(data.tiempo).format('HH:mm:ss a'))
                setQr(data.qr ? data.qr : '');
                
                setDispositivo({dispositivo:data.dispositivo, contactos:data.contactos, contactost:data.contactos})
            })
        }
        Inicio();
        return ()=>{
            Ver_Valores().socket.off('whatsappqr-UECLA');
        }
    },[])
    return (
        <div sx={{ width: '100%', height:window.innerHeight * 0.9}}>
            <div spacing={2}>
                <Item sx={{bgcolor:'#fff', height:window.innerHeight * 0.88, p:5}}>

                    <Stack direction={'column'} spacing={2} 
                        justifyContent="center"
                        alignItems="center"
                    >
                        {qr==='' || (dispositivo && dispositivo.dispositivo)
                            ?  
                            dispositivo.dispositivo
                            ?   <Item>Conectado a {dispositivo.dispositivo.pushname} {dispositivo.dispositivo.me.user} {dispositivo.dispositivo.platform}</Item>     
                            :   <Item>No se ha recibido codigo QR</Item>   
                            :   <QRCode value={qr} size={window.innerHeight * 0.75} logoImage={Logo}/>
                        }
                    
                        {tiempo}
                    </Stack>
                        {dispositivo && dispositivo.contactos
                            ?   <Grid container spacing={2}>
                                    <Grid xs={window.innerWidth > 750 ? 5 : 12}>
                                        <Item sx={{height:window.innerWidth > 750 ? window.innerHeight * 0.70 : window.innerHeight * 0.2}}>
                                            <ListItem
                                                sx={{bgcolor:'#000'}}
                                                secondaryAction={
                                                    checked.length===dispositivo.contactos.length
                                                    ?   <Icon sx={{color:'#fff'}}>delete</Icon>
                                                    :   <Icon sx={{color:'#fff'}}>done_all</Icon>
                                                }
                                            >
                                                <Stack>
                                                    <ListItemButton role={undefined}  dense onClick={SeleccionarT} title={checked.length!==dispositivo.contactos.length ? 'Seleccionar Todos' : 'Quitar todos'}>
                                                        <ListItemText sx={{color:'#fff'}} primary={`Total de contactos: ${dispositivo.contactos.length}, Seleccionados: ${checked.length}`} /> 
                                                    </ListItemButton>
                                                    <TextField id="buscar" label="Buscar" variant="outlined"  sx={{bgcolor:'#fff'}} value={vbuscar} onChange={Buscar}/>
                                                </Stack>
                                            </ListItem>
                                            <Scrollbars sx={{height:window.innerWidth > 750 ? window.innerHeight * 0.50 : window.innerHeight * 0.2}}>
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    {dispositivo.contactos.map((value) => {
                                                        return (
                                                            <ListItem
                                                                key={Generar_id()}
                                                                
                                                                disablePadding
                                                            >
                                                                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={checked.indexOf(value) !== -1}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': value._id }}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText id={value.number} primary={value.name ? value.name : value.pushname} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Scrollbars>
                                            
                                        </Item>
                                    </Grid>
                                    <Grid xs={window.innerWidth > 750 ? 7 : 12}>
                                        <Item sx={{height:window.innerWidth > 750 ? window.innerHeight * 0.70 : window.innerHeight * 0.2}}>
                                            <TextField
                                                id="outlined-mensaje"
                                                label="Mensaje"
                                                value={texto}
                                                fullWidth
                                                multiline
                                                rows={8}
                                                onChange={(event) => {
                                                    setTexto(event.target.value);
                                                }}
                                            />
                                            <div style={{marginTop:10}}/>
                                            <Button variant="contained" endIcon={<SendIcon />} onClick={Enviar}>
                                                Enviar
                                            </Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            :   null
                        }
                </Item>
                
            </div>
        </div>
    );
}
