import React, {useState, useEffect} from 'react';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
import { Ver_Valores} from '../../../constantes';
import { Form_todos, Titulos_todos } from '../../../constantes/formularios';
import Cargando from '../../../componentes/esperar/cargar';

function Evaluaciones (props) {
    const {Datos} = props;
    const [state, setState]= useState({esperar:true});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = (campos, datos) =>{
        let {valores}= datos
        valores={...valores,
            periodo:Datos.periodo,
            docente:{
                _id:Datos.docente._id,
                cedula:Datos.docente.cedula,
                nombres:Datos.docente.nombres,
                apellidos:Datos.docente.apellidos,
            },
            asignatura:Datos.asignatura,
            grado:Datos.grado,
            seccion: Datos.seccion
        }
        return valores
    }

    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_Evaluacion`, Config)
        cambiarState({esperar:false, titulos})
    }
    const Ordenar = (datos)=>{
        // console.log(datos, Datos.grado, Datos.seccion)
        let resultados= datos.filter(f=> {
           return  f.valores.periodo===Datos.periodo 
            && String(f.valores.docente._id)===String(Datos.docente._id)
            && f.valores.grado===Datos.grado 
            && f.valores.seccion===Datos.seccion
            && f.valores.asignatura._id===Datos.asignatura._id  
        }
        )
        return resultados
    }
    useEffect(()=>{
        Inicio()
    }, [props])
    const {Config}= props;
    
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :(
        
            <TablaMultiple
                
                Config={Config ? Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                Condiciones={Condiciones}
                Columnas={2} 
                Form_origen = {Form_todos(`Form_Evaluacion`, Config)}
                Titulo_tabla={'Evaluaciones'}
                Table = {'uecla_Evaluacion'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar`
                }}
                Titulo_dialogo ={(dato)=> dato._id ? `Evaluación`: `Nueva Evaluación`}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
                ordenar={Ordenar}
                Acciones1={props.Titulo ? props.Titulo : ''}
                sinpaginacion={true}
                PEliminar={false}
            />
        
      
    )
}

export default Evaluaciones;