import React, {useState, useEffect} from 'react';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
// import Tabla from '../../../componentes/herramientas/tabla';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import { Ver_Valores, Form_todos, Titulos_todos, conexiones } from '../../../constantes';
import {Condicion_Estudiante, Condicion_Representante} from '../funciones'
import Cargando from '../../../componentes/esperar/cargar';
import Dialogo from '../../../componentes/herramientas/dialogo';
import Estadistica from '../../../componentes/herramientas/estadistica';
import excel from '../../../imagenes/excel.png';
import DescargaExcel from '../../../componentes/descargaExcel';
import moment from 'moment';

function Representante (props) {
    
    const [state, setState]= useState({esperar:true, Dialogo:{open:false}});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = async(campo, datos) =>{
        let {valores}= datos
        switch (campo) {
            case 'uecla_Estudiante':{
                valores= await Condicion_Estudiante(datos);
                return valores
            }
            case 'uecla_Representante':{
                valores = await Condicion_Representante(datos);
                return valores
            }
            default:
                return valores;
        }
    
    }
    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_Representante`, Config)
        cambiarState({esperar:false, titulos})
    }
    const Resumen = async(dato)=>{
        // console.log(dato)
        const Cuerpo= <Estadistica representante={dato}/>
        cambiarState({
            Dialogo:{
                open: !state.Dialogo.open,
                tam:'xl',
                Titulo: `Resumen: ${dato.nombres} ${dato.apellidos}`,
                Cuerpo: Cuerpo,
                Cerrar: ()=>cambiarState({Dialogo: {open:false}}),
            }
        })
    }
    const Titulo = (dato)=>{
        const texto = dato._id 
                        ? `Representante ${dato.nombres} ${dato.apellidos}`
                        : `Nuevo Registro`
        return <Stack
                    direction={ 'row' }
                    spacing={1}
                    justifyContent="center" alignItems="center"
                >
                    {texto}
                    <Box sx={{ml:2}}>
                        <IconButton size="large" color="inherit" title={'Resumen de pagos'} onClick={()=>Resumen(dato)}>
                            <Icon >incomplete_circle</Icon>
                        </IconButton>
                    </Box>
                    { dato._id && !dato.password
                    ?   <Box sx={{ml:2, direction:'revert-layer'}}>
                            <Alert severity="error">
                                {`No ha creado contraseña`}
                            </Alert>
                        </Box>
                        : null
                    }
                </Stack>

    }

    const Filtrar = async(valores)=>{
        let {periodo, grado, campos, camposr} = valores;
        let datos = [];
        periodo=periodo.periodo;
        const seccion = grado.seccion;
        grado=grado.grado 
        const resultado = await conexiones.Solvencias({periodo,grado,seccion});
        let titulos =[];
        let titulosR = campos.map(val=>{
            return {
                title:val.label,
                name:val.name,
                key:'representante-'+val.name,
                field:'representante-'+val.name,
                type:'',
                formato:''
            }
        })
        let titulosE = []
        if(camposr){
            titulosE=[
                // {
                //     title:'Representante',
                //     name:'xxxx',
                //     key:'representante-xxx',
                //     field:'representante-xxx',
                //     type:'',
                //     formato:'',
                //     default:''
                // }
            ]
            camposr.map(val=>{
                
                titulosE=[...titulosE,
                    {
                        title:val.label+'-Representado',
                        name:val.name,
                        key:'estudiante-'+val.name,
                        field:'estudiante-'+val.name,
                        type:'',
                        formato:''
                    }
            ]
                return val;
            })
        } 
            
        titulos=[...titulosR,...titulosE];
        
        for (var i=0; i<resultado.estudiantes.length; i++){
            const est= resultado.estudiantes[i];
            let dato={}
            for (var col=0; col<titulosE.length;col++){
                const campo = titulosE[col].name;
                let valor = est[campo];
                if (valor===undefined || valor===null){
                    valor=''
                }else if (campo==='representante'){
                    valor=`${valor.cedula} ${valor.nombres} ${valor.apellidos}`
                }else if (typeof valor==='object'){
                    valor = valor.titulo ? valor.titulo : valor
                }else if(campo.indexOf('fecha')!==-1){
                    valor= moment(valor).format('DD/MM/YYYY')
                }
                dato['estudiante-'+campo] = valor;
            }
            
            const pos = resultado.representantes.findIndex(f=> (est.representante && f._id===est.representante._id) || (est.representante && f.cedula===est.representante.cedula));
            if (pos!==-1){
                const rep = resultado.representantes[pos];
                for (var colr=0; colr<titulosR.length; colr++){
                    const campo = titulosR[colr].name;
                    let valor = rep[campo];
                    if (valor===undefined || valor===null){
                        valor=''
                    }else if (campo==='representados'){
                        // valor=`${valor.cedula} ${valor.nombres} ${valor.apellidos}`
                        let valorn ='';
                        valor.map(e=>{
                            valorn = valorn==='' 
                                    ? `${e.cedula} ${e.nombres} ${e.apellidos}${e.grado ? ' '+e.grado.titulo : ''}${e.seccion ? ' '+e.seccion.titulo : ''}`
                                    : valorn + `, ${e.cedula} ${e.nombres} ${e.apellidos}${e.grado ? ' '+e.grado.titulo : ''}${e.seccion ? ' '+e.seccion.titulo : ''}`
                            return e
                        })
                        valor = valorn;
                    }else if (typeof valor==='object'){
                        valor = valor.titulo ? valor.titulo : valor
                    }else if(campo.indexOf('fecha')!==-1){
                        valor= moment(valor).format('DD/MM/YYYY')
                    }
                    dato['representante-'+campo] = valor;
                }
            }
            
            datos=[...datos, dato];
        }
        
        return {...resultado, datos, titulos, archivo: `Rep-${periodo}-${grado ? grado : 'Todos'}${seccion ? '-'+seccion : ''}`}
    } 
    const ListaContactos = async() =>{

        cambiarState({
            Dialogo:{
                open: !state.Dialogo.open,
                tam:'xl',
                Titulo: `Generar Excel`,
                Cuerpo: <DescargaExcel 
                            campos={`Form_Representante`}
                            camposr={'Form_Estudiante'}
                            formulario={'Form_DescargaExcel_Representante'} 
                            table={'uecla_Representante'}
                            filtrar= {Filtrar}
                    />,
                Cerrar: ()=>cambiarState({Dialogo: {open:false}}),
            }
        })
        return
        
    }

    useEffect(()=>{
        Inicio()
    }, [props])

    const {Config}= props;
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> : (
        <Box>
            <TablaMultiple
                Config={Config ? Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                Condiciones={Condiciones}
                Columnas={2} 
                Form_origen = {Form_todos(`Form_Representante`, Config)}
                Titulo_tabla={'Representantes'}
                Table = {'uecla_Representante'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar  al representante ${dato.nombres} ${dato.apellidos}`
                }}
                Titulo_dialogo ={Titulo}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
                Tam_dialogo={'lg'}
                AgregarAccion={
                    <div>
                        <IconButton color={'primary'} title={'Descargar en Excel'} onClick={ ListaContactos }>
                            {state.cargando 
                                ? <CircularProgress  />
                                : <img alt={'excel'} src={excel} height={35}/>
                            }
                        </IconButton>
                    </div>
                }
            />
            <Dialogo {...state.Dialogo} config={Config}/>
        </Box>
    )
}

export default Representante;