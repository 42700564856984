import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialogo from '../../../componentes/herramientas/dialogo';
import { conexiones, Ver_Valores, Titulos_todos } from '../../../constantes';
import Tabla from '../../../componentes/herramientas/tabla';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Icon from '@mui/material/Icon';

export default function Pagos(props) {
    const [aabonos, setAbonos] = useState();
    const [dialogo, setDialogo] = useState({open:false});
    const [capture, setCapture] = useState();
    const [actualizar, setActualizar] = useState(false);
    const Inicio = async(cargar=false) =>{
        let {datos} = props
        
        if (cargar && props.Cambio) props.Cambio({
            pantalla:'Pasos', 
            datos, 
            paso:'representante', 
        })
        // let nuevo = Form_todos('Form_Representados');
        let resultado = await conexiones.Leer_C(['uecla_Recibo'],{
            uecla_Recibo:{$and:[{"valores.Abono":true},{"valores.representante._id":datos._id}]}
        })
        let abonos=[]
        if (resultado.Respuesta==='Ok'){
            resultado.datos.uecla_Recibo.sort((a,b)=> a.createdAt > b.createdAt ? -1 : 1).map(val=>{

                let forma = val.valores.Formas_pago.map(f=>{
                    return {...f, valorcambio: val.valores.valorcambio}
                })
                abonos=[...abonos,...forma]
                return val
            })
        }
        let titulos = await Titulos_todos('Titulos_Formaspago_abonos')
        console.log(abonos)

        setAbonos({abonos, titulos})
    }
    
    const EliminarCap = (dato)=>{
        setDialogo({open:false});
        confirmAlert({
            title: `Eliminar`,
            message:`Desea eliminar referencia: ${dato.valores.referencia}`,
            buttons: [
                {label: 'SI', onClick: async()=>{
                    const resp= await conexiones.Eliminar({_id:dato._id},['uecla_Whatsapp_Capture']);
                    if (resp.Respuesta==='Ok'){
                        Inicio(true);
                    }
                }},
                {label: 'NO'}
            ]
        });
    }
    
    const Mostrar1 = (dato)=>{
        const image = dato.valores.media.data;
        setDialogo({
            open:true,
            Titulo: <Box>Captures enviados por WhatSapp
                        <IconButton color={'primary'} title={'Eliminar Capture'} onClick={()=>EliminarCap(dato)} >
                            <Icon style={{color:'red'}}>delete_outline</Icon>
                        </IconButton>
                    </Box>,
            Cuerpo: <Box component={'div'} sx={{textAlign:'center'}}>
                        <img src={`data:image;base64,${image}`} alt={'Capture'} />
                    </Box>,
            Cerrar: ()=>{
                setDialogo({open:false});
            },
        })
    }
   
    const {Config}=props;
    useEffect(()=>{
        // console.log('Crear ActualizarPago')
        setActualizar(true);
        Ver_Valores().socket.on('ActualizarPago',data=>{
            //corregir

            // console.log('Por aqui', actualizar, data)
            if (actualizar)
                Inicio(true);
        })
        Ver_Valores().socket.on('Actualizar',data=>{
            if (data==='uecla_Pago' && actualizar){
                Inicio(true);
            }
        })
        Inicio();
        return ()=>{
            // console.log('Destruir ...')
            setActualizar(false);
            Ver_Valores().socket.off('Actualizar');
            Ver_Valores().socket.off('ActualizarPago');
        }
    },[])
    useEffect(()=>{
        
        if (props.Verificar){
            setCapture(props.Verificar.pendienteWhatsapp);
        }
        return ()=>{
            
        }
    },[props.Verificar])
    const alto = Ver_Valores().tipo==='Web' 
        ?   window.innerHeight * 0.61
        :   window.innerHeight * 0.68
    return (
        <Box sx={{ textAlign:'left', pb:9 }}>
            {capture && capture.length!==0
                ?   <Alert severity="warning">Captures enviados por WhatSapp:
                    {
                        capture.map((val, i)=>
                        <Link href="#" key={val._id}
                            onClick={()=> Mostrar1(val)}
                        >
                            {` ${moment(val.createdAt).format('DD/MM/YYYY')} ${i < capture.length - 1 ? ',' : ''} `}
                        </Link>
                    )}</Alert>
                : null
            }
            {aabonos 
                ?   <Tabla  Titulo={`Representante`}
                        alto={alto}
                        Config={Config ? Config : Ver_Valores().config}
                        titulos={aabonos.titulos}
                        table={'uecla_estudiante'}
                        cantidad={aabonos ? aabonos.abonos.length : null}
                        // cargacompleta={Actualizar_data}
                        datos={aabonos.abonos}
                        Noactualizar
                        cargaporparte={false}
                        sinpaginacion={true} 
                        acciones={
                            <div>
                                <Typography variant={window.innerWidth > 750 ? "h6" : "subtitle1"} component="div" sx={{...Config ? {color:'#fff'} : {} }}>
                                    {props.datos.valores.cedula} {props.datos.valores.nombres} {props.datos.valores.apellidos}
                                </Typography>
                                <Typography variant={window.innerWidth > 750 ? "h6" : "subtitle1"} component="div" sx={{...Config ? {color:'#fff', textAlign:'center'} : {} }}>
                                    Abono Bs. {Number(props.datos.valores.abono ? props.datos.valores.abono : 0).toFixed(2)}  Abono ${Number(props.datos.valores.abonod ? props.datos.valores.abonod : 0).toFixed(2)} 
                                </Typography>
                            </div>
                        }
                        
                    />
                :   null
            
            }
            <Dialogo  {...dialogo} config={props.Config}/>
        </Box>
    );
}
