import React, {useState, useEffect} from 'react';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
import { Ver_Valores} from '../../../constantes';
import { Form_todos, Titulos_todos } from '../../../constantes/formularios';
import Cargando from '../../../componentes/esperar/cargar';

function PNotas (props) {
    
    const [state, setState]= useState({esperar:true});
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    
    const Condiciones = (campos, datos) =>{
        let {valores}= datos
        return valores
    }

    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_PNotas`, Config)
        cambiarState({esperar:false, titulos})
    }
    const ordenar = (dato)=>{
        let lista = [];
        const nuevo = dato.filter(f=> {
            const pos = lista.indexOf(f._id);
            if (pos===-1){
                lista = [...lista, f._id];
            }
            return pos===-1
        }).sort((a,b)=> a.valores.periodo.periodo>b.valores.periodo.periodo ? -1 :1)
        return nuevo
    }
    useEffect(()=>{
        Inicio()
    }, [props])
    const {Config}= props;
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :(
        
            <TablaMultiple
                ordenar={ordenar}
                Config={Config ? Config : Ver_Valores().config}
                multiples_valores={true}
                Agregar_mas={false}
                
                Columnas={2} 
                Form_origen = {Form_todos(`Form_PNotas`, Config)}
                Titulo_tabla={'Periodos Activos'}
                Table = {'uecla_PNota'}
                Eliminar_props={(dato)=>{
                    return `Desea eliminar`
                }}
                Titulo_dialogo ={(dato)=> dato._id ? `Registro `: `Nuevo Registro `}
                Titulos_tabla = {state.titulos}
                cargaporparte = {true}
               
                
            />
        
      
    )
}

export default PNotas;