import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Formulario from '../herramientas/formulario';
import Tabla from '../herramientas/tabla';
import { Icon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Form_todos, genera_formulario, Ver_Valores, AExcell } from '../../constantes';
import excel from '../../imagenes/excel.png';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgb(5, 30, 52)',
    backgroundImage: "linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));

export default function DescargaExcel(props) {
    const [state, setState] = React.useState({});
    const [tabla, setTabla] = React.useState()

    const Cambio = (nuevo) =>{
        setState({...state, ...nuevo});
    }
    const ExportarS = ()=>{
        const nuevo = tabla.datos ? tabla.datos.map((val1,i)=>{
            let val={ID:i+1};
            for (var i=0; i<tabla.titulos.length; i++){
                const campo = tabla.titulos[i].field;
                val[tabla.titulos[i].title.toUpperCase()]= val1[campo]
            }
            return val
        }) : [];
        console.log(tabla.archivo.length)
        AExcell(nuevo,tabla.archivo,`${tabla.archivo}.xlsx`);
    }
    const Filtrar = async(valores)=>{
        const Config = Ver_Valores().config;
        let respuesta;
        if (props.filtrar){
            respuesta = await props.filtrar(valores);
            const {datos, titulos, archivo}=respuesta
            setTabla({datos, titulos, cantidad: datos.length, archivo})
        }
        return respuesta
    }
    const Inicio = async()=>{
        const Config = Ver_Valores().config;
        const campos = Form_todos(props.campos);
        const camposr = props.camposr ? Form_todos(props.camposr) : undefined;
        const formulario1 = await genera_formulario({ valores:{}, campos: Form_todos(props.formulario) });
        formulario1.titulos[0].value.campos.lista = campos.value.map((val,id)=>{
            return {...val, key:val.name, id, _id:id, disabled:false}
        })
        if (camposr){
            formulario1.titulos[0].value.camposr.lista = camposr.value.map((val,id)=>{
                return {...val, key:val.name, id, _id:id, disabled:false}
            })
        }
        const periodos =formulario1.titulos[1].value.periodo.lista.sort((a,b) => a.periodo> b.periodo ? -1 : 1)
        formulario1.titulos[1].value.periodo.value= periodos[0];
        formulario1.titulos[1].value.periodo.lista= periodos;
        let grados =  Ver_Valores().config.Listas.lista_colegio_grado;
        let seccion = Ver_Valores().config.Listas.lista_colegio_seccion;
        let ngrados=[{titulo:`Todos`, grado:undefined, seccion: undefined}];
        grados.map(val=>{
            let dato= seccion.map(sec=>{
                return {...val, titulo:`${val.titulo} ${sec.titulo}`, grado:val.titulo, seccion: sec.titulo}
            })
            ngrados=[...ngrados, ...dato]
            return dato
        })
        
        formulario1.titulos[1].value.grado.value= ngrados[0];
        formulario1.titulos[1].value.grado.lista=ngrados
        
        formulario1.botones=[
            {
              name:'filtrar', label:'Vista Previa', title:'Vista Previa',
              variant:"contained", color:"success", icono:<Icon>find_in_page</Icon>,
              onClick: Filtrar, validar:'true', 
              sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
            }
        ]
        Cambio({formulario:formulario1})
    }

    React.useEffect(()=>{
        Inicio()
        return ()=>{
            
        }
    },[])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0.5}>
                <Grid xs={12}>
                    <Item>
                        <Formulario {...state.formulario}
                            config={{
                                Estilos:{
                                    Input_label:{color:'#fff'},
                                    Input_fondo:'#fff',
                                    Input_input:{color:'#fff'}
                                }
                            }}
                        />
                    </Item>
                </Grid>
                <Grid sx={12}>
                    <Item>
                        {tabla 
                            ?
                                <Tabla  Titulo={"Datos"}
                                    alto={Ver_Valores().tipo==='Web' ? window.innerHeight * 0.71 : window.innerHeight * 0.73}
                                    Config={Ver_Valores().config}
                                    titulos={tabla.titulos}
                                    table={props.table}
                                    cantidad={tabla.cantidad ? tabla.cantidad : null}
                                    datos={tabla.datos}
                                    cargaporparte={false }
                                    Noactualizar
                                    sinpaginacion={true}
                                    acciones={
                                        <div>
                                            <IconButton color={'primary'} title={'Descargar en Excel'} onClick={ ExportarS }>
                                                {tabla.cargando 
                                                    ? <CircularProgress  />
                                                    : <img alt={'excel'} src={excel} height={35}/>
                                                }
                                            </IconButton>
                                        </div>
                                    }
                                    
                                        
                                />
                            : null
                        }
                    </Item>
                </Grid>
                
            </Grid>
        </Box>
    );
}
