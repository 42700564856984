import Inicio from './';
import Representante from './representante';
import Estudiante from './estudiante';
import Docente from './docente';
import Recibo from './recibo';
import Solvencia from './solvencias';
import RInscripcion from './inscripcion';
import RAranceles from './arancel';
import Referencia from './referencia';
import RCensos from './censos';
import EnviarWhatsapp from './enviowhatsapp';
import NominaDocente from './nomina docente';
import Docenten from './nomina docente/docenten'
import Personal from './nomina docente/personal';

export const colegio_administrativo_pantallas={
    Administrativo: Inicio,
    Inscripciones: RInscripcion,
    Aranceles: RAranceles,
    Representantes:Representante,
    Estudiantes:Estudiante,
    Docentes:Docente,
    Recibos:Recibo,
    Solvencias:Solvencia,
    Referencias: Referencia,
    Censos:RCensos,
    Nomina: NominaDocente,
    Docenten:Docenten,
    Personaln:Personal,
    EnviarWHATSAPP: EnviarWhatsapp
}