import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Dialogo from '../../../../componentes/herramientas/dialogo';
import { conexiones, Ver_Valores, Titulos_todos, genera_formulario, Form_todos } from '../../../../constantes';
import Formulario from '../../../../componentes/herramientas/formulario';
import Tabla from '../../../../componentes/herramientas/tabla';
import Esperar from '../../../../componentes/esperar/cargar';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Icon } from '@mui/material';
import HorasDocentes from './horasdocente';
import BonoTransporte from './bonotransporte';
import BonoAlimentacion from './bonoalimentacion';
import Nomina from './nomina';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgb(5, 30, 52)',
    backgroundImage: "linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    
}));

export default function NominaDocente(props) {
    const [docentes, setDocentes] = useState();
    const [dialogo, setDialogo] = useState({open:false});
    const [actualizar, setActualizar] = useState(false);
    const [value, setValue] = React.useState(0);
    const Inicio = async(fecha = new Date()) =>{
        console.log(fecha)
        setActualizar(true);
        let formulario = await genera_formulario({valores:{fecha}, campos: Form_todos('Form_filtro_nomina_docente') })
        const periodos =formulario.titulos[0].value.periodo.lista.sort((a,b) => a.periodo> b.periodo ? -1 : 1)
        formulario.titulos[0].value.periodo.value= periodos[0];
        formulario.titulos[0].value.periodo.lista= periodos;
        formulario.titulos[0].value.fecha.onAccept = CambioFecha
        
        let resultado = await conexiones.NominaDocente(fecha, periodos[0].periodo)
        let _id
        let datos=[]
        let quincena = '';
        let titulos = await Titulos_todos('Titulos_Nomina_Docente')
        if (resultado.Respuesta==='Ok'){
            _id= resultado._id;
            datos= resultado.docentes.map(dato=>{
                let total=0
                Object.keys(dato).map(val=>{
                    const dia = val.split('-');
                    if (['Lu','Ma','Mi','Ju','Vi','Sa','Do'].indexOf(dia[0])!==-1){
                        total+=Number(dato[val])
                    }
                    return val
                })
                
                return {...dato, total}
            });
            datos=[...datos,...resultado.personal ? resultado.personal : []]
            let persod=[];
            let person=[];
            datos.map(val=>{
                if (val.valordia){
                    person=[...person, {...val, bgcolor:"#74a5fc"}]
                }else{
                    persod=[...persod,val]
                }
                return val
            })
            persod=persod.sort((a,b)=> a.apellidos< b.apellidos ? -1 :1 )
            person=person.sort((a,b)=> a.apellidos< b.apellidos ? -1 :1 ) 
            datos=[...persod, ...person];
            console.log(datos)
            quincena=resultado.quincena
            resultado.quincena.dias.map(val=>{
                titulos=[...titulos,{
                    field:`${val.dia}-${val.fecha}`,
                    title:`${val.dia} ${val.fecha}`,
                    tipo:'general',
                    type:'',
                    formato: `${val.dia}-${val.fecha}`.indexOf('F-')===-1 
                        ? (dato)=>{
                            return  <Typography 
                                        color=  {   dato[`condicion-${val.dia}-${val.fecha}`] && dato[`condicion-${val.dia}-${val.fecha}`].color && dato[`condicion-${val.dia}-${val.fecha}`]._id!==0
                                                    ?   "#fff"
                                                    : dato[`${val.dia}  F-${val.fecha}`] === dato[`${val.dia}-${val.fecha}`] 
                                                        ? '#12c600'
                                                        :'#0039c6'
                                                } 
                                        title = {dato[`condicion-${val.dia}-${val.fecha}`] && dato[`condicion-${val.dia}-${val.fecha}`].titulos
                                                    ? dato[`condicion-${val.dia}-${val.fecha}`].titulo
                                                    : ''
                                        }
                                        sx={{fontWeight:'bold',textAlign:'center',
                                            bgcolor: dato[`condicion-${val.dia}-${val.fecha}`] && dato[`condicion-${val.dia}-${val.fecha}`].color
                                                        ?  dato[`condicion-${val.dia}-${val.fecha}`].color
                                                        : ''
                                        }}
                                    >
                                        {dato[`${val.dia}-${val.fecha}`]}
                                    </Typography>
                                    
                        } 
                        : (dato)=>{
                            return  <Typography color={'#d0d4d0'} sx={{fontWeight:'bold',textAlign:'center'}}>
                                        {dato[`${val.dia}-${val.fecha}`]}
                                    </Typography>
                                    
                        }
                }]
                return val
            })
        }
        titulos=[...titulos,{
            field:`Horas`,
            title:`Horas en Quincena`,
            tipo:'general',
            type:'',
            formato: (dato)=>{
                let total = 0;
                Object.keys(dato).map(val=>{
                    const dia = val.split('-');
                    if (['Lu','Ma','Mi','Ju','Vi','Sa','Do'].indexOf(dia[0])!==-1){
                        total+=Number(dato[val])
                    }
                    return val
                })
                return  <Typography 
                            sx={{fontWeight:'bold',textAlign:'center'}}
                        >
                            {total}
                        </Typography>
                            
            } 
              
        }]

        setDocentes({_id, datos, titulos, formulario,quincena:quincena.quincena })
        setActualizar(false);
    }
    const CambioFecha = async(valores)=>{
        Inicio(valores['$d']);
    }
    const GuardarHoras = async(valores)=>{
        const {_id, datos, quincena} = docentes;
        const pos =  datos.findIndex(val=> val._id===valores._id)
        if (pos!==-1){
            datos[pos]=valores
        }else{
            datos=[...datos, valores]
        }
        let nuevos= await conexiones.Guardar({_id:_id, valores:{quincena, datos}, multiples_valores:true},'uecla_Nomina_docente');
        if (nuevos.Respuesta==='Ok'){
            setDocentes({...docentes, datos})
            setDialogo({...dialogo,open:false})
        }
        return nuevos
    }
    const GuardarTrnasporte = async(valores)=>{
        const {_id, datos, quincena} = docentes;
        const pos =  datos.findIndex(val=> val._id===valores._id)
        console.log(valores);
        if (pos!==-1){
            datos[pos]=valores
        }else{
            datos=[...datos, valores]
        }
        let nuevos= await conexiones.Guardar({_id:_id, valores:{quincena, datos}, multiples_valores:true},'uecla_Nomina_docente');
        if (nuevos.Respuesta==='Ok'){
            setDocentes({...docentes, datos})
            setDialogo({...dialogo,open:false})
        }
        return nuevos

    }

    //------------------------funciones de nomina---------------------
    const bonoTransporte=(dato)=>{
        const diaslaborados= dato.ultimo> 15 ? 0 : 20;
        const valordia= 30;
        const resultado = Number(dato.diast ? dato.diast : diaslaborados) * Number(dato.bonot ? dato.bonot : valordia)
        return resultado
    }
    const diaBonoAlimentacion=(dato)=>{
        const horasdia = 8;
        const resultado = dato.diasl ? dato.diasl : dato.valordia ? 10 : dato.total/horasdia;
        return resultado
    }
    const diaValorAlimentacion=(dato)=>{
        const valordia = 2.5;
        const resultado = dato.bonoa ? dato.bonoa : valordia;
        return resultado
    }
    const bonoAlimentacion=(dato)=>{
        const diaslaborados= diaBonoAlimentacion(dato);
        const valordia= diaValorAlimentacion(dato);
        const resultado = diaslaborados * valordia;
        return resultado
    }
    const montoQuincena = (dato)=>{
        const valorfijo = 50;
        const diasq=15;
        const resultado = dato.valordia ? Number(dato.valordia)*diasq :Number(dato.valorhora ? dato.valorhora : valorfijo) * Number(dato.total)
        return resultado
    }

    const montoTotal = (dato)=>{
        const transporte = bonoTransporte(dato);
        const alimentacion = bonoAlimentacion(dato)* Ver_Valores().tasa.USD;
        const quincena = montoQuincena(dato);
        const desc = dato.desc ? Number(dato.desc) : 0
        const resultado = transporte + alimentacion + quincena - desc;
        return resultado
    }
    useEffect(()=>{
        // console.log('Crear ActualizarPago')
        setActualizar(true);        
        Inicio();
        return ()=>{
            // console.log('Destruir ...')
            setActualizar(false);
        }
    },[])
    useEffect(()=>{
        
        return ()=>{
            
        }
    },[props.Verificar])
    const {Config}=props;
    const alto = Ver_Valores().tipo==='Web' 
        ?   window.innerHeight * 0.65
        :   window.innerHeight * 0.68
    return !docentes ? <Esperar open={true} Config={Config}/> :(
        <Box sx={{  }}>
            <Item style={{height: 'auto', color:'#fff'}}>
                <Formulario {...docentes.formulario}
                    config={{
                        Estilos:{
                            // Input_label:{color:'#fff'},
                            // Input_fondo:'#fff',
                            // Input_input:{color:'#fff'}
                        }
                    }}
                />
                <Typography sx={{textAlign:'center'}}>{docentes.quincena}</Typography>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Horas Docentes" icon={<Icon>switch_account</Icon>} />
                    <BottomNavigationAction label="Bono de Transporte" icon={<Icon>directions_bus</Icon>} />
                    <BottomNavigationAction label="Bono de Alimentación" icon={<Icon>dinner_dining</Icon>} />
                    <BottomNavigationAction label="Nomina Quincena" icon={<Icon>payments</Icon>} />
                </BottomNavigation>
            </Item>
            {value===0
                ?   <HorasDocentes Docentes={{...docentes}} Guardar={GuardarHoras}/>
                :   value===1
                ?   <BonoTransporte Docentes={{...docentes}} Guardar={GuardarTrnasporte} bonoTransporte={bonoTransporte}/>
                :   value===2
                ?   <BonoAlimentacion Docentes={{...docentes}} 
                        Guardar={GuardarTrnasporte} 
                        diaBonoAlimentacion={diaBonoAlimentacion}
                        diaValorAlimentacion={diaValorAlimentacion}
                        bonoAlimentacion={bonoAlimentacion}
                    />
                :   value===3
                ?   <Nomina Docentes={{...docentes}} 
                        Guardar={GuardarTrnasporte} 
                        bonoTransporte={bonoTransporte}
                        diaBonoAlimentacion={diaBonoAlimentacion}
                        diaValorAlimentacion={diaValorAlimentacion}
                        bonoAlimentacion={bonoAlimentacion}
                        montoQuincena={montoQuincena}
                        montoTotal={montoTotal}
                    />
                :null
            }
            <Dialogo  {...dialogo} config={props.Config}/>
            <Esperar open={actualizar} Config={Config}/>
        </Box>
    );
}
