import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialogo from '../../../../componentes/herramientas/dialogo';
import { conexiones, Ver_Valores, Titulos_todos, genera_formulario, Form_todos, Moneda } from '../../../../constantes';
import Formulario from '../../../../componentes/herramientas/formulario';
import Tabla from '../../../../componentes/herramientas/tabla';
import Esperar from '../../../../componentes/esperar/cargar';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';

export default function BonoAlimentacion(props) {
    const [docentes, setDocentes] = useState();
    const [dialogo, setDialogo] = useState({open:false});
    const [actualizar, setActualizar] = useState(false);
    const Inicio = async(fecha = new Date()) =>{
        setActualizar(true);
        let titulos=[
            props.Docentes.titulos[0],
            props.Docentes.titulos[1],
            {
                title:"Dias Laborados",
                field:"diasl",
                tipo:"",
                formato:(dato)=> `${Number(props.diaBonoAlimentacion(dato)).toFixed(2)}`
            },
            {
                title:"Valor Dia $",
                field:"bonoa",
                tipo:"",
                formato:(dato)=> `${Number(props.diaValorAlimentacion(dato)).toFixed(2)}`
            },
            {
                title:"Bono Alimentación $",
                field:"bonoatd",
                tipo:"general",
                formato:(dato)=> <Typography textAlign={'right'}>{Moneda(props.bonoAlimentacion(dato),'$',true)}</Typography>
            },
            {
                title:"Bono Alimentación",
                field:"bonoat",
                tipo:"monto",
                formato:(dato)=> `${Number(props.bonoAlimentacion(dato)* Ver_Valores().tasa.USD).toFixed(2)}`
            }

        ]
        setDocentes({...props.Docentes, titulos})
        setActualizar(false);
    }
   
    const Guardar = async(valores)=>{
        const nuevos= await props.Guardar(valores)
        if (nuevos.Respuesta==='Ok'){
            setDialogo({...dialogo,open:false})
        }
        return nuevos
    }
    const Abrir = async(columna, valor)=>{
        const {titulos} = docentes;
        
        if (['cedula','nombres','apellidos','bonoat','bonoatd'].indexOf(columna.field)!==-1){
            return
        }
         
        const valorN= {

            [columna.field]:valor[columna.field],
        }
        let formato = {
            columna:2,
            value:[
                {
                    label: `${valor.nombres} ${valor.apellidos}`,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    variant:'h6',
                    solo:true
                },
                {
                    label: `${columna.title}`,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    variant:'h6',
                    solo:true
                },
                {
                    label: `${columna.title}`,
                    name:columna.field,
                    nombre:columna.field,
                    tipo:'input',
                    title: `${columna.title}`,
                    type:'number'
                },
            ]
        }
        let Titulo = 'Bono de Alimnetación';
        
        
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:'xs',
            fullWidth: true,
            Titulo,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    useEffect(()=>{
        // console.log('Crear ActualizarPago')
        setActualizar(true);        
        Inicio();
        return ()=>{
            // console.log('Destruir ...')
            setActualizar(false);
        }
    },[props])
    
    const Config=Ver_Valores().config;
    const alto = Ver_Valores().tipo==='Web' 
        ?   window.innerHeight * 0.50
        :   window.innerHeight * 0.68
    return !docentes ? <Esperar open={true} Config={Config}/> :(
        <Box sx={{  }}>
            
            {docentes 
                ?   <Tabla  Titulo={`Docentes`}
                        alto={alto}
                        Config={Config ? Config : Ver_Valores().config}
                        titulos={docentes.titulos}
                        table={'uecla_docente'}
                        cantidad={docentes ? docentes.datos.length : null}
                        // cargacompleta={Actualizar_data}
                        datos={docentes.datos}
                        Noactualizar
                        cargaporparte={false}
                        sinpaginacion={true} 
                        AccionColumna={Abrir}
                        
                        
                    />
                :   null
            
            }
            <Dialogo  {...dialogo} config={Config}/>
            <Esperar open={actualizar} Config={Config}/>
        </Box>
    );
}
